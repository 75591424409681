import { Box, Container, Grid, Typography } from "@mui/material";
import "@fontsource/roboto";

function App() {

  const scopes = [
    {
      text: 'Processos Tradings',
      url: 'http://app.megatrading.com.br/'
    },
    {
      text: 'Operações Construbank',
      url: 'http://admin.construbank.net.br/'
    },
    {
      text: 'Licitações',
      url: '',
    },
    {
      text: 'Área do Corretor',
      url: '',
    },
    {
      text: 'Make Housi',
      url: '',
    },
    {
      text: 'Controle de Combustível',
      url: '',
    },


  ]

  const openUrlInNewTab = (url) => {
    if (url === '') {
      alert('Em desenvolvimento')
    }
    else {
      window.open(url, '_blank');
    }
  }
  return (
    <Container maxWidth='lg' >
      <Box width='100%' height='100vh'>
        <Typography mt={2} variant="h4" fontFamily='Roboto' color='white' padding={1} textAlign='center'>Portal de aplicações Indcon Holding</Typography>
        <Box mt={3} padding={1} height={'70vh'} >
          <Grid container spacing={0} gap={3} justifyContent='center' >
            {
              scopes.map(scope => (
                <Grid
                  onClick={() => openUrlInNewTab(scope.url)}
                  item
                  xs={3}
                  sx={{
                    border: scope.url !== '' ? '1px solid #007bff' : '1px solid yellow',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 2,
                    color: scope.url !== '' ? '#007bff' : 'yellow',
                    transition: 'background-color 0.3s ease',
                    '&:hover': {
                      backgroundColor: scope.url !== '' ? '#007bff' : 'yellow',
                      color: scope.url !== '' ? '#fff' : 'black',
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography
                    variant="h7"
                    fontWeight='600'
                  >
                    {
                      scope.text
                    }
                  </Typography>
                </Grid>

              ))
            }
          </Grid>
        </Box>
        <Box
          width='100%'
          padding={2}
          textAlign='center'
          mt={2}
        >
          <Typography variant='body1' color='#ddd'>
            Aplicações desenvolvidas por Igor Gomes - OG CODER
          </Typography>
        </Box>
      </Box>
    </Container >
  );
}

export default App;
